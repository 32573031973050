.left-map-container {
    padding: 1rem;

    .left-map-search {
        position: relative;

        .lisence_search_ex {
            position: absolute;
            background-color: #f4f4f4;
            z-index: 10;
            left: 65px;
            top: 0;
            padding: 0.5rem;
            box-shadow: 2px 2px 10px gray;
            width: 75%;

            .lisence_search_item {
                margin-bottom: 0.5rem;
                background-color: #fff;
            }

            .lisence_search_btn {
                display: flex;
                margin: auto;
                margin-top: 1rem;
            }
        }
    }
    .tab_ {
        margin: 1rem 0;
        background-color: aliceblue;

        .col-6 {
            border-bottom: 1px solid gray;
        }

        p {
            text-align: center;
            font-weight: 700;
            margin-top: 1rem;
        }

        .active {
            border-bottom: 3px solid #11998e;
        }
    }

    .list_lisence_info {
        margin-top: 1rem;
        overflow-y: scroll;
        height: 640px;

        .lisence_address {
            font-size: 1.125rem;
            font-weight: 600;

            p {
                text-align: center;
            }
        }

        .lisence_info_value {
            font-weight: 600;
        }
        .slide {
            p {
                text-align: center;
                font-size: 1.1rem;
                font-weight: 500;
            }
            img {
                width: 100%;
                max-height: 300px;
                padding: 1rem;
                border-radius: 25px;
            }
        }
        .attached_files{
            margin-top: 2rem;
            p {
                font-size: 1.1rem;
                font-weight: 500;
            }
        }
    }

    .br-end {
        width: 60%;
        border-bottom: 1px solid #11998e;
        display: flex;
        margin: auto;
        margin-bottom: 1rem;
    }

    .title {
        padding: 1rem 0;

        h3 {
            font-size: 1.4rem;
            font-weight: 700;
            color: #0055ab;
            text-align: center;

        }
    }

    .left-map-content {
        overflow-y: scroll;
        max-height: 616px;
        margin-top: 2rem;

        .lisence_item {
            background: rgb(89, 89, 89);
            background: linear-gradient(0deg, rgba(89,89,89,1) 0%, rgba(17,153,142,1) 100%);
            margin-bottom: 1rem;
            border-radius: 10px;
            .lisence_item_address{
                font-size: 1.1rem;
                font-weight: 500;
            }
            p {
                color: #fff;
                padding: 0.5rem 0.5rem 0 0.5rem;
                margin-bottom: 0;
                font-size: 16px;
            }
            .lisence_item_view {
                padding-bottom: 1rem;
                p {
                    cursor: pointer;
                    &:hover {
                        color: yellow;
                    }
                }
            }
        }
    }
}
.container_ci {
    max-width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 568px;
    margin: 1rem;

    span {
        color: blue;
    }

    .name_ci {
        padding: 1rem;

        h3 {
            font-size: 24px;
            color: #4e4e4e;
            margin-bottom: 15px;
            font-weight: 600;
            line-height: 1.2;
        }

        p {
            font-size: 16px;
            color: rgb(78, 78, 78);
        }
    }

    .content_ci {
        padding: 0 1rem;


        .value_ci {
            p {
                font-weight: 600;
            }
        }
    }

    .slide {
        img {
            width: 100%;
            max-height: 300px;
            padding: 1rem;
        }
    }

    .slider-home-page {
        // position: relative;
        top: -57px;

        img {
            width: 100%;
        }

        .slick-slider {
            .slick-dots {
                bottom: 30px;

                li {
                    width: 10px;
                    height: 10px;
                    margin: 3px;

                    button {
                        width: 12px;
                        height: 12px;
                        background-color: rgba(0, 0, 0, 0);
                        border: 1px solid #fff;
                        border-radius: 50%;
                        padding: 4px;
                    }

                    button::before {
                        font-size: 12px;
                        width: 12px;
                        height: 12px;
                        opacity: 0;
                        color: #fff;
                        line-height: 15px;
                    }

                    &.slick-active {
                        button::before {
                            opacity: 1;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
.add-lisence-file {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    height: 787px;
    background-color: #fff;

    .list_pl_info {
        overflow-y: auto;
        overflow-x: hidden;
        height: 388px;

        .pl_info_name {
            font-weight: 700;
        }

        .pl_info_ {
            padding: 0 2rem 0 0.5rem;

            p {
                padding: 5px;
                margin: 0;
            }

            .pl_info_item {
                border: 1px solid #000;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .pl_info_item_ {
                border: 1px solid #000;
                display: flex;
                align-items: center;
            }
        }
    }
}
.left__menu {

    .project__info {
        position: relative;
        animation-name: botToTop;
        animation-duration: .2s;

        .header__info {
            p {
                color: #fff;
                background-color: #11998e;
                font-weight: 600;
            }
        }

        .list__info {
            overflow: scroll;
            max-height: 713px;

            .detail__info {

                .title_name {
                    p {
                        font-weight: 700;
                        padding: 5px;
                        margin: 0;
                    }

                    border: 2px solid #000;
                }

                table {
                    width: 100%;

                    th,
                    td {
                        border: 1px solid #000;
                        padding: 5px;
                        width: 50%;
                    }
                }

            }
        }

    }
}
@keyframes botToTop {
    from {top:600px}
    to {top: 0}
  }
.map_lisence_container {
    height: 787px;
    background: #fff;
    .col-12 {
        padding: 0;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}